<template>
  <b-card
    no-body
    :class="{'open': isOpen}"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header

      :class="{'collapsed': !isOpen}"
      :aria-expanded="isOpen ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!isOpen)"
    >
      <slot name="header">
        <span
          class="lead collapse-title"
          style="margin-left: 3px;"
        >
          <feather-icon
            v-if="icon"
            :icon="icon"
            size="22"
          />  {{ title }}</span>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body class="cardWithScroll">
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCollapse,
} from 'bootstrap-vue'

import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,

  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    forceClose: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
    isOpen() {
      return this.isVisible
    },
  },
  watch: {
    forceClose(val) {
      if (val) {
        this.visible = false
      }
    },
  },

  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>
<style scoped>
.cardWithScroll {
  height: 500px !important;
  overflow-y: scroll !important;
}
</style>
